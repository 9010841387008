import React from 'react';
import About from '../components/About/About';
import Banner from '../components/Banner/Banner';
import Contact from '../components/Contact/Contact';
import Footer from '../components/Footer/Footer';
import Projects from '../components/Projects/Projects';
import Skills from '../components/Skills/Skills';
import Navbar from '../components/Navbar/Navbar';
import Experience from '../components/Experience/Experience';
import NavbarAvatarPreview from '../components/MobileNav/NavbarAvatarPreview';

const Main = () => {
    return (
        <div className='mx-auto'>
            <NavbarAvatarPreview />
            <Navbar />
            <Banner></Banner>
            <About></About>
            <Skills></Skills>
            <Experience></Experience>
            <Projects></Projects>
            <Contact></Contact>
            <Footer></Footer>
        </div>
    );
};

export default Main;