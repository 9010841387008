import { SiJavascript, SiHtml5, SiCss3, SiBootstrap, SiTailwindcss, SiReact, SiFirebase, SiNodedotjs, SiExpress, SiMongodb, SiRedux, SiTypescript } from 'react-icons/si';
import { FaGitAlt, FaGithub } from 'react-icons/fa';

export const projects = [
    {
        id: 1,
        name: "Woodpecker",
        description: "Woodpecker is a full-stack secondhand furniture resale website. The website consist of a dashboard, user role, payment system, log in system, and much more.",
        clientLink: "https://github.com/numangit/woodpecker-12-client",
        liveLink: "https://woodpecker-12.web.app/",
        thumbnail: "https://i.ibb.co/T8RhxWW/woodpecker-full.jpg",
        detailsLink: "woodpeckerDetails",
    },
    {
        id: 2,
        name: "Rise.io",
        description: "Rise.io is a front end course website. It is a SPA page which makes the user experience smooth. It is a course website that provides multiple paid courses.",
        clientLink: "https://github.com/numangit/riseio-10",
        liveLink: "https://riseio.web.app/",
        thumbnail: "https://i.ibb.co/wLBRZRS/riseio-full.jpg",
        detailsLink: "riseioDetails"
    },
    {
        id: 3,
        name: "Do.it",
        description: "Do.it is a full stack task management website. It allows users to log in and set goals, mark and note to the completed tasks, and upload pictures.",
        clientLink: "https://github.com/numangit/do.it",
        liveLink: "https://do-it-io.web.app/",
        thumbnail: "https://i.ibb.co/v4X2YQW/doitiful.jpg",
        detailsLink: "doitDetails"
    }
];

export const skillsData = [
    {
        id: 1,
        name: 'Git',
        aos: 'flip-left',
        icon: <FaGitAlt />
    },
    {
        id: 2,
        name: 'GitHub',
        aos: 'zoom-in-down',
        icon: <FaGithub />
    },
    {
        id: 3,
        name: 'HTML',
        aos: 'zoom-in-down',
        icon: <SiHtml5 />
    },
    {
        id: 4,
        name: 'CSS',
        aos: 'fade-right',
        icon: <SiCss3 />
    },
    {
        id: 5,
        name: 'Bootstrap',
        aos: 'fade-down-right',
        icon: <SiBootstrap />
    },
    {
        id: 6,
        name: 'Tailwind',
        aos: 'flip-right',
        icon: <SiTailwindcss />
    },
    {
        id: 7,
        name: 'JavaScript',
        aos: 'fade-left',
        icon: <SiJavascript />
    },
    {
        id: 8,
        name: 'React JS',
        aos: 'flip-left',
        icon: <SiReact />
    },
    {
        id: 9,
        name: 'Firebase',
        aos: 'zoom-in-down',
        icon: <SiFirebase />
    },
    {
        id: 10,
        name: 'Node JS',
        aos: 'zoom-in-up',
        icon: <SiNodedotjs />
    },
    {
        id: 11,
        name: 'Express JS',
        aos: 'zoom-in-down',
        icon: <SiExpress />
    },
    {
        id: 12,
        name: 'MongoDB',
        aos: 'flip-left',
        icon: <SiMongodb />
    },
    {
        id: 13,
        name: 'Redux',
        aos: 'flip-right',
        icon: <SiRedux />
    },
    {
        id: 14,
        name: 'TypeScript',
        aos: 'zoom-in-up',
        icon: <SiTypescript />
    },
];