import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Woodpecker = () => {

    //scroll at the top after page is rendered
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='min-h-screen p-3 md:p-1'>
            <div
                data-aos="fade-right"
                className="btn-group btn-group-vertical lg:btn-group-horizontal ml-4 lg:ml-8 m-5 mt-10">
                <Link to="/" className="btn btn-outline w-40 font-bold border-l-2 border-0 hover:bg-accent hover:border-[#dca54c] hover:text-[#dca54c] duration-500 hover:font-bold">Home</Link>
                <a href="myresume-abdulalnuman.pdf" download="myresume-abdulalnuman.pdf" className="btn btn-outline w-40 font-bold border-l-2 border-0 hover:bg-accent duration-500  hover:border-[#dca54c] hover:text-[#dca54c] hover:font-bold">My Resume</a>
            </div>
            <div
                className="my-6 card lg:card-side backdrop-blur-sm bg-white/70 shadow-xl rounded-2xl md:w-10/12 mx-auto">
                <figure className='lg:w-1/2'><img className="object-fill" src="https://i.ibb.co/DVjbxD3/woodpecker-preview.png" alt="Album" loading="lazy" /></figure>
                <div className="card-body lg:p-6 lg:w-1/2">
                    <h2 className="card-title text-4xl font-semibold">Woodpecker</h2>
                    <p className='text-md'>Woodpecker is a secondhand furniture resale website. The website includes a dashboard UI that displays different content based on the type of user account and more. </p>
                    <div className='flex justify-end space-x-1'>
                        <a href="https://github.com/numangit/woodpecker-12-client" target="_blank" rel="noreferrer"><button className='btn btn-sm btn-accent rounded-tr-lg rounded-bl-lg'>Source Code</button></a>
                        <a href="https://woodpecker-12.web.app/" target="_blank" rel="noreferrer"><button className='btn btn-sm btn-accent rounded-tr-lg rounded-bl-lg'>Live Demo</button></a>
                    </div>
                </div>
            </div>
            <div className='my-3 text-md  md:w-10/12 mx-auto backdrop-blur-sm bg-white/70 shadow-xl rounded-2xl p-4'>
                <h2 className="card-title text-2xl font-semibold my-2">Technologies & Tools :</h2>
                <div className='space-x-2 space-y-2'>
                    <div className="badge badge-ghost">Swiper JS</div>
                    <div className="badge badge-ghost">React Strip</div>
                    <div className="badge badge-ghost">Lottie Files</div>
                    <div className="badge badge-ghost">React Hot Toast</div>
                    <div className="badge badge-ghost">Axios</div>
                    <div className="badge badge-ghost">TanStack Query</div>
                    <div className="badge badge-ghost">React icons</div>
                    <div className="badge badge-ghost">Tailwind CSS</div>
                    <div className="badge badge-ghost">Daisy UI</div>
                    <div className="badge badge-ghost"> Animation</div>
                    <div className="badge badge-ghost">Firebase</div>
                    <div className="badge badge-ghost">JWT</div>
                    <div className="badge badge-ghost">Node JS</div>
                    <div className="badge badge-ghost">Express</div>
                    <div className="badge badge-ghost">MongoDB</div>
                    <div className="badge badge-ghost">React JS</div>
                    <div className="badge badge-ghost">imgBB</div>
                </div>
                <h2 className="card-title text-2xl font-semibold my-2">Key Features :</h2>
                <p>
                    ● Payment Gateway has been implemented for users to securely pay for their products.
                    Strip.js has been used to add this functionality.
                </p>
                <p>
                    ● Dashboard panel has been included, where based on a user role certain routes and
                    options are displayed.
                </p>
                <p>
                    ● Uploading pictures directly from local storage functionality has been included using
                    third-party image hosting services like imgBB
                </p>
            </div>

        </div>
    );
};

export default Woodpecker;