import React from "react";
import { BsGithub, BsLinkedin } from "react-icons/bs";

const Contact = () => {
  return (
    <div
      className="container mx-auto lg:h-[450px] lg:flex items-center scroll-mt-20 md:mb-12 my-5 lg:my-16"
      id="contact"
    >
      <div
        data-aos="fade-right"
        data-aos-duration="400"
        className="pl-4 lg:pl-8 md:mr-auto my-8"
      >
        <p className="text-4xl md:mb-8 pl-4 font-extrabold border-l-4 border-slate-900">
          Let's get in <span className="text-[#dca54c]">touch</span>
        </p>
        {/* <p className='mt-8 text-2xl font-semibold'>Let's get in touch</p> */}
        <div className="mt-3">
          <p className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5 mr-2 sm:mr-6"
            >
              <path
                fillRule="evenodd"
                d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span>Chittagong, Bangladesh</span>
          </p>
          <p className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5 mr-2 sm:mr-6"
            >
              <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
            </svg>
            <span>+8801860896896</span>
          </p>
          {/* <p className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2 sm:mr-6">
                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                        </svg>
                        <span>abdulnumanjob@gmail.com</span>
                    </p> */}
          <a
            href="https://github.com/numangit"
            className="flex items-center"
            target="_blank"
            rel="noreferrer"
          >
            <span className="w-5 h-5 mr-2 sm:mr-6">
              <BsGithub />
            </span>
            <span>/numangit</span>
          </a>
          <a
            href="https://www.linkedin.com/in/abdul-al-numan/"
            className="flex items-center"
            target="_blank"
            rel="noreferrer"
          >
            <span className="w-5 h-5 mr-2 sm:mr-6">
              <BsLinkedin />
            </span>
            <span>/in/abdul-al-numan</span>
          </a>
        </div>
      </div>
      <form
        data-aos="fade-up"
        className="relative mx-3 lg:mx-auto mt-8 p-8 bg-gray-900 rounded-xl"
        action="https://formsubmit.co/fcaf6bdbb098719accdc6bc85bd20aca"
        method="POST"
      >
        <span className="absolute inset-x-0 bottom-0 h-1.5 bg-gradient-to-r from-[#e4ab4f] via-[#dca54c] to-black rounded-b-lg"></span>
        <div className="text-center">
          <div>
            <input
              type="text"
              placeholder="Your full name"
              name="name"
              className="my-2 input input-bordered w-full  lg:w-[450px] text-sm"
              required
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Your email address"
              name="email"
              className="my-2 input input-bordered w-full  lg:w-[450px] text-sm"
              required
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Subject"
              name="subject"
              className="my-2 input input-bordered w-full  lg:w-[450px] text-sm"
              required
            />
          </div>
          <div>
            <textarea
              type="text"
              className="my-2 textarea textarea-bordered w-full lg:w-[450px]"
              name="message"
              placeholder="Your message"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="btn rounded-md btn-accent hover:text-[#dca54c] w-full"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
