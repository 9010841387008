import { Dialog } from '@headlessui/react';
import { FaBars } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';
import { useState } from 'react';
import logo from '../../assets/logo.png';
import { BsGithub, BsLinkedin } from 'react-icons/bs';

const navigation = [
  { name: 'About', href: "#about" },
  { name: 'Skills', href: "#skills" },
  { name: 'Experience', href: "#experience" },
  { name: 'Projects', href: "#projects" },
  { name: 'Contact', href: "#contact" },
];

export default function NavbarAvatarPreview() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-slate-50/20 backdrop-blur-md fixed w-full z-50 lg:hidden">
      <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <button
            // onClick={() => setLocation('home')}
            className="-m-1.5 p-1.5">
            <span className="sr-only">Abdul Al Numan</span>
            <img
              className="h-8 w-auto"
              src={logo}
              alt="cube"
            />
          </button>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-900"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <FaBars className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <button
              key={item.name}
              // onClick={() => setLocation(`${item.location}`)}
              className="text-base font-semibold leading-6 text-gray-900 hover:text-slate-700">
              {item.name}
            </button>
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <button
            className="text-base font-semibold leading-6 text-gray-900 hover:text-slate-700">
            Log in <span aria-hidden="true">&rarr;</span>
          </button>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-50 bg-slate-50/20 backdrop-blur-md" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white/10 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 backdrop-blur-md">
          <div className="flex items-center justify-between">
            <button
              className="-m-1.5 p-1.5">
              <span className="sr-only">Abdul Al Numan</span>
              <img
                className="h-8 w-auto"
                src={logo}
                alt="cube"
              />
            </button>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-900"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <GrClose className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-white/60">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    onClick={() => setMobileMenuOpen(false)}
                    className="-mx-3 w-full block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-center text-gray-900 hover:text-[#dca54c] hover:bg-gray-700 "
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="flex justify-center py-2">
                <a href="https://github.com/numangit" target="_blank"
                  className="text-3xl mx-2 text-center" rel="noreferrer"><BsGithub /></a>
                <a href="https://www.linkedin.com/in/abdul-al-numan/" target="_blank"
                  className="text-3xl mx-2 text-center" rel="noreferrer"><BsLinkedin /></a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}