import React from "react";

const PreLoader = () => {
  // bg-gradient-to-r from-teal-500 via-purple-500 to-pink-500 backdrop-blur-xl

  return (
    <div className="w-full h-screen flex justify-center items-center bg-gradient-to-r from-rose-100 to-teal-100">
      <div className="lg:mx-auto lg:w-2/5 text-3xl lg:text-4xl text-center mx-2 font-bold ">
        <span data-aos="fade-up"
          className="mx-2 my-1" >
          Hello <span className="wave">🖐</span>
        </span>
        <br />
        <span data-aos="fade-up" data-aos-easing="ease-in-back" data-aos-delay="100"
          className="mx-2 my-1" >
          Welcome
        </span>
        <span data-aos="fade-up" data-aos-easing="ease-in-back" data-aos-delay="400"
          className="mx-2 my-1" >
          to
        </span>
        <span data-aos="fade-up" data-aos-easing="ease-in-back" data-aos-delay="700"
          className="mx-2 my-1" >
          my
        </span>
        <span data-aos="fade-up" data-aos-easing="ease-in-back" data-aos-delay="1000"
          className="mx-2 my-1" >
          portfolio
        </span>
      </div>
    </div>
  );
};

export default PreLoader;