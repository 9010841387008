import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Quizify = () => {

    //scroll at the top after page is rendered
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='min-h-screen p-3 md:p-1'>
            <div
                data-aos="fade-right"
                className="btn-group btn-group-vertical lg:btn-group-horizontal ml-4 lg:ml-8 m-5 mt-10">
                <Link to="/" className="btn btn-outline w-40 font-bold border-l-2 border-0 hover:bg-accent hover:border-[#dca54c] hover:text-[#dca54c] duration-500 hover:font-bold">Home</Link>
                <a href="myresume-abdulalnuman.pdf" download="myresume-abdulalnuman.pdf" className="btn btn-outline w-40 font-bold border-l-2 border-0 hover:bg-accent duration-500  hover:border-[#dca54c] hover:text-[#dca54c] hover:font-bold">My Resume</a>
            </div>
            <div
                data-aos="fade-up" data-aos-duration="500"
                className="my-6 card lg:card-side shadow-xl rounded-2xl md:w-10/12 mx-auto backdrop-blur-sm bg-white/70 flex-col-reverse">
                <div className="card-body lg:p-6 lg:w-1/2">
                    <h2 className="card-title text-4xl font-semibold">Quizify</h2>
                    <p className='text-md'>Quizify is a minimal quiz Web App. It is a SPA page which makes the user experience smooth. It consists of basic web development topics based on which multiple questions are asked.</p>
                    <div className='flex justify-start space-x-1 '>
                        <a href="https://github.com/numangit/quizify-9" target="_blank" rel="noreferrer"><button className='btn btn-sm btn-accent rounded-tr-lg rounded-bl-lg'>Source Code</button></a>
                        <a href="https://fancy-donut-4a4ca6.netlify.app/" target="_blank" rel="noreferrer"><button className='btn btn-sm btn-accent rounded-tr-lg rounded-bl-lg'>Live Demo</button></a>
                    </div>
                </div>
                <figure className='lg:w-1/2 rounded-md'><img className="object-fill" src="https://i.ibb.co/mRKJDXK/quizify-preview.png" alt="Album" loading="lazy" /></figure>
            </div>

            <div className='my-3 text-md  md:w-10/12 mx-auto backdrop-blur-sm bg-white/70 shadow-xl rounded-2xl p-4'>
                <h2 className="card-title text-2xl font-semibold my-2">Technologies & Tools :</h2>
                <div className='space-x-2 space-y-2'>
                    <div className="badge badge-ghost">React JS</div>
                    <div className="badge badge-ghost">Rest API</div>
                    <div className="badge badge-ghost">React Toastify</div>
                    <div className="badge badge-ghost">Lottie Files</div>
                    <div className="badge badge-ghost">Rechart</div>
                    <div className="badge badge-ghost">Font Awesome</div>
                    <div className="badge badge-ghost">React Router Dom</div>
                    <div className="badge badge-ghost">React icons</div>
                    <div className="badge badge-ghost">React Bootstrap</div>
                </div>
                <h2 className="card-title text-2xl font-semibold my-2">Key Features :</h2>
                <p>
                    ● Dynamically quizzes are added in every every route based on the topic.
                </p>
                <p>
                    ● Quiz counter functionality is added in the quiz section to notify the user about their score with the help of React state management.
                </p>
                <p>
                    ● Dynamic routes are created based on the chosen topic using react router dom
                </p>
                <p>
                    ● Charts added to show the statistics using Rechart.
                </p>
                <p>
                    ● Toast Notifications added for a better user experience with the help of React Toastify.
                </p>
            </div>
        </div>
    );
};

export default Quizify;