import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ProjectsDetails from "./components/ProjectsDetails/ProjectsDetails";
import Quizify from "./components/ProjectsDetails/Quizify";
import Riseio from "./components/ProjectsDetails/Riseio";
import Woodpecker from "./components/ProjectsDetails/Woodpecker";
import Main from "./Layout/Main";
import { useEffect, useState } from "react";
import PreLoader from "./components/Preloader/PreLoader";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  //preloader
  useEffect(() => {
    // Simulating an API call or fetching data from a server
    setTimeout(() => {
      setIsLoading(false);
    }, 3500); // Replace this with your actual fetch request

    // Clean up the effect
    return () => {
      // Cancel any pending requests or timers if needed
    };
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main></Main>,
    },
    {
      path: "projectsDetails",
      element: <ProjectsDetails></ProjectsDetails>,
    },
    {
      path: "woodpeckerDetails",
      element: <Woodpecker></Woodpecker>,
    },
    {
      path: "riseioDetails",
      element: <Riseio></Riseio>,
    },
    {
      path: "doitDetails",
      element: <Quizify></Quizify>,
    },
    {
      path: "*",
      element: <div>This route not found: 404</div>,
    },
  ]);

  return (
    <>
      {isLoading ? (
        <PreLoader />
      ) : (
        <div className="lg:relative">
          <RouterProvider router={router}></RouterProvider>
        </div>
      )}
    </>
  );
}

export default App;
