import React from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { MdOutlineDoubleArrow } from 'react-icons/md';

const Experience = () => {
  return (
    <div className="container mx-auto lg:h-[400px] lg:flex items-center scroll-mt-36 my-5" id="experience">
      <div data-aos="fade-right" data-aos-duration="400" className="pl-4 lg:pl-8 lg:w-1/4 md:mr-auto my-8">
        <p className='text-4xl pl-4 font-extrabold border-l-4 border-[#5b5b5c]'>
          My&nbsp;<span className='text-[#dca54c]'>Experience</span>
        </p>
      </div>
      <div data-aos="fade-up"
        className="relative block overflow-hidden rounded-xl bg-gray-900 p-4 sm:p-6 lg:p-8 mt-5 mx-3 lg:w-[750px] lg:mx-auto"
      >
        <span
          className="absolute inset-x-0 bottom-0 h-1.5 bg-gradient-to-r from-[#e4ab4f] via-[#dca54c] to-black"
        ></span>

        <a href="https://dakoo.app/" target='_blank' rel="noreferrer" className="sm:flex sm:justify-between sm:gap-4">
          <div>
            <h3 className="text-lg font-bold hover:text-[#dca54c] text-white ">
              CITYTECH INNOVATIONS PRIVATE LIMITED (Dakoo)
            </h3>
            {/* 
            <p className="mt-1 text-xs font-medium text-gray-200">Full Stack Developer Internship Certificate</p> */}
          </div>

          <div className="hidden sm:block sm:shrink-0">
            <img
              alt="Paul Clapton"
              src="https://dakoo.app/wp-content/uploads/2020/10/dakoo-2.png"
              className="h-12 w-fit rounded-xl object-cover shadow-sm"
            />
          </div>
        </a>

        <dl className="mt-6 flex gap-4 sm:gap-6">
          <div className="flex flex-col-reverse">
            <dt className="text-sm font-bold text-gray-200">Full Stack Developer</dt>
            <dd className="text-xs text-gray-300">Position</dd>
          </div>
          <div className="flex flex-col-reverse">
            <dt className="text-sm font-bold text-gray-200">
              27th Jan 2023 - 15th Mar 2023
            </dt>
            <dd className="text-xs text-gray-300">Duration</dd>
          </div>
          <div className="flex flex-col-reverse">
            <a href="https://drive.google.com/file/d/1t-gJBOxpCPbRNDN4LeTFvcRTVCEGIPTX/view" target='_blank' rel="noreferrer">
              <dt className="flex text-sm font-bold text-gray-200 hover:text-[#dca54c] ">
                <BiLinkExternal />&nbsp;
                View certificate
              </dt>
            </a>
            {/* <dd className="text-xs text-gray-300">Certificate</dd> */}
          </div>
        </dl>

        <div className="mt-5">
          <div className="text-sm text-gray-400">
            <p className='font-semibold'>Tools & Technologies:</p>
            <p className='ml-3 flex'>
              <MdOutlineDoubleArrow /> &nbsp;
              React, React-Native, MongoDB, Node JS, Vercel, Asana, Bitbucket</p>
            <p className='font-semibold mt-3'>Responsibilities: </p>
            <p className='ml-3 flex'>
              <MdOutlineDoubleArrow /> &nbsp;
              Analyzed requirements and built frontend part of the website and mobile app.</p>
            <p className='ml-3 flex'>
              <MdOutlineDoubleArrow /> &nbsp;
              Code repaired and optimized the Ui performance on website and mobile app.
            </p>
            <p className='ml-3 flex'>
              <MdOutlineDoubleArrow /> &nbsp;
              Created API using Node JS, database using MongoDB and hosted the server.
            </p>
          </div>
        </div>


      </div>
    </div>
  );
};

export default Experience;