import { projects } from '../../data/data';
import ProjectCard from './ProjectCard';

const Projects = () => {

    return (
        <div className="container mx-auto scroll-mt-28 my-5" id="projects">
            <div data-aos="fade-right" data-aos-duration="400" className="pl-4 lg:pl-8">
                <p className='text-4xl pl-4 font-extrabold border-l-4 border-slate-900 mt-11'>
                    My&nbsp;<span className='text-[#dca54c]'>Projects</span>
                </p>
            </div>
            {/* overflow-x  */}
            {/* <div className='my-4 py-4 mx-3 md:mx-1 flex snap-x gap-5 md:gap-7 lg:gap-9  overflow-x-scroll no-scrollbar'> */}
            <div className='my-8 mx-3 md:mx-1 flex flex-wrap gap-5 md:gap-7 lg:gap-9 justify-center'>
                {projects.map(((project, i) => <ProjectCard key={i} project={project} />))}
            </div >
            {/* <div className='flex justify-center mt-2'>
                <button className='btn btn-sm btn-accent rounded-md hover:text-[#dca54c] my-4 md:mt-4 md:mb-2 text-xs'>view all</button>
            </div> */}
        </div >
    );
};

export default Projects;