import React from 'react';
import { useScroll } from '../../hooks/useScroll';

const Navbar = () => {
    const navbar = useScroll();

    return (

        <div className={`justify-center w-full hidden
        ${navbar && 'lg:flex fixed top-0 backdrop-blur-sm bg-gradient-to-t from-white/0 to-white z-50 '}`}>
            <div
                className=" z-50 btn-group lg:btn-group-horizontal ml-4 lg:ml-8 m-5 mt-10 font-lex text-xs">
                <a href="#about" className="bg-[#141516] text-white border-[#5b5b5c] btn btn-outline w-40 font-semibold border-l-2 border-0 hover:text-base hover:border-[#dca54c] hover:text-[#dca54c] duration-500 ">About</a>
                <a href="#skills" className="bg-[#141516] text-white border-[#5b5b5c] btn btn-outline w-40 font-semibold border-l-2 border-0 hover:text-base hover:border-[#dca54c] hover:text-[#dca54c] duration-500 ">Skills</a>
                <a href="#experience" className="bg-[#141516] text-white border-[#5b5b5c] btn btn-outline w-40 font-semibold border-l-2 border-0 hover:text-base  hover:border-[#dca54c] hover:text-[#dca54c] duration-500 ">Experience</a>
                <a href="#projects" className="bg-[#141516] text-white border-[#5b5b5c] btn btn-outline w-40 font-semibold border-l-2 border-0 hover:text-base  hover:border-[#dca54c] hover:text-[#dca54c] duration-500 ">Projects</a>
                <a href="#contact" className="bg-[#141516] text-white border-[#5b5b5c] btn btn-outline w-40 font-semibold border-l-2 border-0 hover:text-base  hover:border-[#dca54c] hover:text-[#dca54c] duration-500 ">Contact</a>
                <a href="https://drive.google.com/file/d/17Xh-k0vFtxJH364FZZL1CoPodQsCG2mJ/view?usp=sharing" rel="noreferrer" target='_blank' className="bg-[#141516] text-white border-[#5b5b5c] btn btn-outline w-40 font-semibold border-l-2 border-0 hover:text-base duration-500   hover:border-[#dca54c] hover:text-[#dca54c]">Resume</a >
            </div>
        </div>
    );
};

export default Navbar;