import React from "react";
import { BsGithub, BsLinkedin } from "react-icons/bs";

const Footer = () => {
  return (
    <footer className="bg-neutral">
      <div className="container mx-auto footer items-center p-4 text-neutral-content ">
        <div className="items-center grid-flow-col text-base">
          <p>
            Made with <span className="text-red-500">❤</span> By Numan
          </p>
        </div>
        <div className="grid-flow-col gap-4 md:place-self-center md:justify-self-end">
          <a
            href="https://github.com/numangit"
            className="text-lg mx-1"
            target=" _blank"
            rel="noreferrer"
          >
            <BsGithub />
          </a>
          <a
            href="https://www.linkedin.com/in/abdul-al-numan/"
            className="text-lg md:text-xl mx-1"
            target=" _blank"
            rel="noreferrer"
          >
            <BsLinkedin />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
