import React from 'react';
import { skillsData } from '../../data/data';

const Skills = () => {

    return (
        <div className="container mx-auto lg:h-[400px] lg:flex items-center scroll-mt-32" id="skills">
            <div
                data-aos="fade-right" data-aos-duration="400"
                className="pl-4 lg:pl-8 lg:w-1/4 md:mr-auto my-5">
                <p className='text-4xl my-8 pl-4 font-extrabold border-l-4 border-[#5b5b5c]'>
                    My&nbsp;<span className='text-[#dca54c]'>Skills</span>
                </p>
            </div>
            <div className='grid grid-cols-2 md:grid-cols-5 gap-3 mx-3 lg:mx-auto'>
                {
                    skillsData?.map((skill) => <div
                        key={skill.id}
                        data-aos={skill.aos}
                        className='p-4 border-[#dca54c] shadow-lg backdrop-blur-sm bg-gray-900 rounded-md flex text-gray-50 hover:text-[#dca54c] text-base'>{skill.icon}&nbsp;{skill.name}</div>
                    )
                }
            </div>
        </div>
    );
};

export default Skills;