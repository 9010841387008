import { useState } from 'react';
import { Link } from 'react-router-dom';

const ProjectCard = ({ project }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}
      className="block p-6 rounded-xl shadow-[2px_12px_11px_0px_#1a202c] bg-gray-900 relative md:max-w-[365px] my-3 md:my-2">
      <div className={`duration-300 ${isHover && "-translate-y-9 rounded-xl shadow-[0px_5px_0px_0px_#dca54c]"}`}>
        <div
          className={`h-56 duration-[5000ms] transition-all ease-linear bg-cover rounded-xl
          ${isHover ? 'bg-bottom' : 'bg-top-right'}`}
          style={{ backgroundImage: `url(${project.thumbnail})` }}
        >
        </div>
      </div>
      <div className="mt-3">
        <dl>
          <div>
            <dd className={`${isHover && 'text-xl'} duration-500 text-lg font-bold text-white my-2`}>{project.name}</dd>
          </div>
          <div>
            <dt className="sr-only">Address</dt>
            <dd className="font-medium text-sm text-gray-300">{project.description}</dd>
          </div>
        </dl>
        <div className="mt-6 flex items-center md:justify-start md:gap-8 text-xs">
          <a href={project.liveLink} target="_blank" rel="noreferrer" className="flex items-center mx-2 md:mx-0 gap-1">
            <svg
              className="h-4 w-4 mx-1 text-[#dca54c]"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
              />
            </svg>
            <div className="">
              <p className="text-white hover:text-[#dca752] font-semibold">Live Demo</p>
            </div>
          </a>
          <Link to={project.detailsLink}
            className="flex items-center mx-2 md:mx-0 gap-1">
            <svg
              className="h-4 w-4 text-[#dca54c]"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
              />
            </svg>
            <div className="">
              <p className="text-white hover:text-[#dca752]  font-semibold">More Details</p>
            </div>
          </Link>
        </div>
      </div >
    </div >

    //scroll-x
    // <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}
    //   className="flex-shrink-0 snap-center block p-6 rounded-xl shadow-[2px_12px_11px_0px_#1a202c] bg-gray-900 relative max-w-[365px] my-3 md:my-2">
    //   <div className={`duration-300 ${isHover && "-translate-y-9 rounded-xl shadow-[0px_5px_0px_0px_#dca54c]"}`}>
    //     <div
    //       className={`h-56 duration-[5000ms] transition-all ease-linear bg-cover rounded-xl
    //       ${isHover ? 'bg-bottom' : 'bg-top-right'}`}
    //       style={{ backgroundImage: `url(${project.thumbnail})` }}
    //     >
    //     </div>
    //   </div>
    //   <div className="mt-3">
    //     <dl>
    //       <div>
    //         <dd className={`${isHover && 'text-xl'} duration-500 text-lg font-bold text-white my-2`}>{project.name}</dd>
    //       </div>
    //       <div>
    //         <dt className="sr-only">Address</dt>
    //         <dd className="font-medium text-sm text-gray-300">{project.description}</dd>
    //       </div>
    //     </dl>
    //     <div className="mt-6 flex items-center md:justify-start md:gap-8 text-xs">
    //       <a href={project.liveLink} target="_blank" rel="noreferrer" className="flex items-center mx-2 md:mx-0 gap-1">
    //         <svg
    //           className="h-4 w-4 mx-1 text-[#dca54c]"
    //           xmlns="http://www.w3.org/2000/svg"
    //           fill="none"
    //           viewBox="0 0 24 24"
    //           stroke="currentColor"
    //         >
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
    //           />
    //         </svg>
    //         <div className="">
    //           <p className="text-white hover:text-[#dca752] font-semibold">Live Demo</p>
    //         </div>
    //       </a>
    //       <Link to={project.detailsLink}
    //         className="flex items-center mx-2 md:mx-0 gap-1">
    //         <svg
    //           className="h-4 w-4 text-[#dca54c]"
    //           xmlns="http://www.w3.org/2000/svg"
    //           fill="none"
    //           viewBox="0 0 24 24"
    //           stroke="currentColor"
    //         >
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
    //           />
    //         </svg>
    //         <div className="">
    //           <p className="text-white hover:text-[#dca752]  font-semibold">More Details</p>
    //         </div>
    //       </Link>
    //     </div>
    //   </div >
    // </div >
  );
};

export default ProjectCard;