import { useEffect, useState } from "react";

export function useScroll() {
  const [navbar, setNavbar] = useState(null);

  //navbar scroll changeBackground function
  const changeBackground = () => {
    // console.log(window.scrollY)
    if (window.scrollY >= 280) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeBackground)
  });

  return navbar;
};