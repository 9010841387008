import React from 'react';
import { MdDownload } from 'react-icons/md';
import resume from '../../assets/abdulalnuman-resume.pdf';

const About = () => {
    return (
        <div className="container my-5 mx-auto lg:h-[400px] lg:flex items-center scroll-mt-32" id="about">
            <div
                data-aos="fade-right" data-aos-duration="400"
                className='my-8 ml-4 lg:ml-8 lg:w-1/4 md:mr-auto '>
                <p
                    className='text-4xl pl-4 font-extrabold border-l-4 border-[#5b5b5c]'>
                    About <span className='text-[#dca54c]'><span className='font-fancy'>M</span>e</span>
                </p>
            </div>
            <div data-aos="fade-up" className='mx-3 lg:mx-auto my-10 text-lg px-4 py-6 md:px-6 md:py-6 text-start lg:w-[750px] bg-gray-900 text-gray-300 rounded-xl font-sans backdrop-blur-sm'>
                <span
                    className="absolute inset-x-0 bottom-0 h-1.5  from-[#e4ab4f] via-[#dca54c] to-black bg-gradient-to-r rounded-b-md"
                ></span>
                <p className="">
                    {/* <span className="text-[#dca54c] font-semibold "> Abdul Al Numan</span>. */}
                    Hello! My name is <strong className='animate-text bg-gradient-to-r from-[#e4ab4f] via-yellow-200 to-[#dca54c] bg-clip-text text-transparent'>Abdul Al Numan</strong> and I enjoy creating things that live on the Internet. My interest in web development started back in 2022 when I decided to build a website through a youtube tutorial turns out you need to know a lot of HTML, CSS, and Javascript.
                </p>
                <p className="mt-4">
                    Fast-forward to today and I've built over 15+ full-stack projects and completed an internship. Right now, getting more real-world coding experience is my main priority.
                </p>
                <a href={resume} download="Abdul Al Numan-resume" className='btn btn-sm btn-accent rounded-md hover:text-[#dca54c] duration-200 my-4 md:mt-4 md:mb-2 text-sm'><MdDownload />&nbsp;
                    Download resume</a>
            </div>
            {/* </div> */}
        </div>
    );
};

export default About;