import Lottie from "lottie-react";
import hey from '../../assets/42842-male-avatar.json';
import down from '../../assets/69545-scroll-down.json';
import { useEffect, useState } from "react";
import TextTransition, { presets } from 'react-text-transition';

const Banner = () => {

    // text animation 
    const [index, setIndex] = useState(0);

    const texts = ['A friendly human specie', 'Frontend Web Developer', 'MERN Stack Developer', 'React JS Developer'];

    useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => index + 1),
            3000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);
    return (
        <div className='container mx-auto flex items-center h-[830px] lg:h-[600px] lg:text-center' >
            <div className='mx-auto'>
                <div className='lg:hidden mx-auto w-3/4 md:w-2/4'>
                    <Lottie animationData={hey} loop={true} />
                </div>
                {/* <div className="w-72 relative mx-auto lg:hidden">
                    <img className="rounded-full w-[90px]  absolute top-10 left-[100px]" src={profile} alt="" />
                    <Lottie animationData={frame} loop={false} />
                </div> */}
                <div className='text-3xl md:text-4xl lg:text-6xl mx-1 md:mx-0 flex flex-col items-center md:mt-4 lg:mt-16'>
                    <p className='font-semibold text-4xl lg:text-6xl text-center animate-bounce temporary-bounce z-50'>
                        <span className='animate-text bg-gradient-to-r from-teal-500 via-purple-500 to-pink-500 bg-clip-text text-transparent'>
                            Abdul Al Numan
                        </span>
                    </p>
                    {/* <div className="px-auto">
                        <TextTransition
                            className='w-full mx-auto m-1 z-0 text-3xl text-center lg:text-5xl font-semibold'
                            springConfig={presets.wobbly}
                        >
                            {texts[index % texts.length]}
                        </TextTransition>
                    </div> */}
                    <p className="text-center text-3xl lg:text-5xl font-semibold">
                        MERN Stack Developer
                    </p>
                </div>
                <div
                    data-aos="fade-right"
                    data-aos-delay="500"
                    className="hidden lg:flex ml-4 lg:ml-8 m-5 mt-8 font-lex text-xs text-black">
                    <div>
                        <a href="#about" className="my-1 md:my-0 lg:bg-[#141516] border-[#5b5b5c] btn btn-outline w-40 font-semibold border-l-2 border-0  lg:text-center text-start lg:text-white hover:text-base hover:border-[#dca54c] hover:text-[#dca54c] duration-500 ">About</a>
                    </div>
                    <div>
                        <a href="#skills" className="my-1 md:my-0 lg:bg-[#141516] border-[#5b5b5c] btn btn-outline w-40 font-semibold border-l-2 border-0  lg:text-center text-start lg:text-white hover:text-base hover:border-[#dca54c] hover:text-[#dca54c] duration-500 ">Skills</a>
                    </div>
                    <div>
                        <a href="#experience" className="my-1 md:my-0 lg:bg-[#141516] border-[#5b5b5c] btn btn-outline w-40 font-semibold border-l-2 border-0  lg:text-center text-start lg:text-white hover:text-base  hover:border-[#dca54c] hover:text-[#dca54c] duration-500 ">Experience</a>
                    </div>
                    <div>
                        <a href="#projects" className="my-1 md:my-0 lg:bg-[#141516] border-[#5b5b5c] btn btn-outline w-40 font-semibold border-l-2 border-0  lg:text-center text-start lg:text-white hover:text-base  hover:border-[#dca54c] hover:text-[#dca54c] duration-500 ">Projects</a>
                    </div>
                    <div>
                        <a href="#contact" className="my-1 md:my-0 lg:bg-[#141516] border-[#5b5b5c] btn btn-outline w-40 font-semibold border-l-2 border-0  lg:text-center text-start lg:text-white hover:text-base  hover:border-[#dca54c] hover:text-[#dca54c] duration-500 ">Contact</a>
                    </div>
                    <div>
                        <a
                            href="https://drive.google.com/file/d/17Xh-k0vFtxJH364FZZL1CoPodQsCG2mJ/view?usp=sharing"
                            className="my-1 md:my-0 lg:bg-[#141516] border-[#5b5b5c] btn btn-outline w-40 font-semibold border-l-2 border-0  lg:text-center text-start lg:text-white hover:text-base duration-500   hover:border-[#dca54c] hover:text-[#dca54c]" target='_blank' rel="noreferrer">Resume</a >
                    </div>
                </div>
                <div className='mx-auto w-36 md:w-24 mt-10'>
                    <Lottie animationData={down} loop={true} />
                </div>
            </div>
        </div>
    );
};

export default Banner;