import React from 'react';
import { Link } from 'react-router-dom';

const ProjectsDetails = () => {
    return (
        <div className='min-h-screen'>
            <div
                data-aos="fade-right"
                className="btn-group btn-group-vertical lg:btn-group-horizontal ml-4 lg:ml-8 m-5 mt-10 sticky top-0">
                <Link to="/" className="btn btn-outline w-40 font-bold border-l-2 border-0 hover:bg-accent hover:border-[#dca54c] hover:text-[#dca54c] duration-500 hover:font-bold">Home</Link>
                <a href="myresume-abdulalnuman.pdf" download="myresume-abdulalnuman.pdf" className="btn btn-outline w-40 font-bold border-l-2 border-0 hover:bg-accent duration-500  hover:border-[#dca54c] hover:text-[#dca54c] hover:font-bold">My Resume</a>
            </div>
            <div
                className="my-6 card lg:card-side backdrop-blur-sm bg-white/70 shadow-xl rounded-2xl md:w-10/12 mx-auto">
                <figure className='lg:w-1/2'><img className="object-fill" src="https://i.ibb.co/DVjbxD3/woodpecker-preview.png" alt="Album" loading="lazy" /></figure>
                <div className="card-body lg:p-6 lg:w-1/2">
                    <h2 className="card-title text-4xl font-semibold">Woodpecker</h2>
                    <p className='text-md'>Woodpecker is a secondhand furniture resale website. The website includes a dashboard UI that displays different content based on the type of user account and more. </p>
                    <div className='flex justify-end space-x-1'>
                        <Link to="projectsDetails">
                            <button className='btn btn-sm btn-accent rounded-tr-lg rounded-bl-lg'>Details</button>
                        </Link>
                        <a href="https://github.com/numangit/woodpecker-12-client" target="_blank" rel="noreferrer"><button className='btn btn-sm btn-accent rounded-tr-lg rounded-bl-lg'>Source Code</button></a>
                        <a href="https://woodpecker-12.web.app/" target="_blank" rel="noreferrer"><button className='btn btn-sm btn-accent rounded-tr-lg rounded-bl-lg'>Live Demo</button></a>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ProjectsDetails;